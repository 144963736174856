const breakpoints = {
    desktop: 1024
}

export const lang = () => {
    return location.pathname
        .replace(window.contextPath, '')
        .split('/')
        .filter(x => x.length)
        .shift()
}

export const dataLayerPush = (payload: unknown) => {
    if (window.dataLayer) {
        const dataLayer = window.dataLayer || [];
        dataLayer.push(payload);
    }
}


export const checkIsMobile = () => {
    return window.innerWidth < breakpoints.desktop
}