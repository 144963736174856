/* eslint-disable dot-notation */
import type { App } from 'vue'
import LzNav from '@/components/nav/LzNav.vue'
import LzNavItem from '@/components/nav/LzNavItem.vue'
import LzProto from '@/components/LzProto.vue'
import LzAccordion from '@/components/base/LzAccordion.vue'
import LzIcon from '@/components/base/LzIcon.vue'
import LzAccordionManager from '@/components/base/LzAccordionManager.vue'

export default {
    install(app: App) {
        app.component('lz-proto', LzProto)
        app.component('lz-icon', LzIcon)
        app.component('lz-nav', LzNav)
        app.component('lz-nav-item', LzNavItem)
        app.component('lz-accordion-manager', LzAccordionManager)
        app.component('lz-accordion', LzAccordion)

        /**
         * global properties
         */
        app.config.globalProperties.$contextPath = window['contextPath']
        // also provide them, so we can use them in composition api components with `inject`
        app.provide('contextPath', window['contextPath'])
    }
}
